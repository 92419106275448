import http from '../clients/Axios'

export default class BatchService {
  async get(obat_id) {
    const res = await http.get(`/obat/${obat_id}/batch`)
    return res
  }

  async add(form) {
    const res = await http.post(`/sales/detail/${form.sod_id}/batch`, form)
    return res
  }

  async addPurchase(form) {
    const res = await http.post(`/purchase/detail/${form.pod_id}/batch`, form)
    return res
  }

  async delete(form) {
    const res = await http.delete(
      `/sales/detail/${form.sod_id}/batch/${form.batch_id}`
    )
    return res
  }

  async deletePurchase(form) {
    const res = await http.delete(
      `/purchase/detail/${form.pod_id}/batch/${form.batch_id}`
    )
    return res
  }

  async setPick(id) {
    const res = await http.put(`/sales/order/${id}`, { status: 'pick' })
    return res
  }

  async setRcvd(id) {
    const res = await http.put(`/purchase/order/${id}`, { status: 'rcvd' })
    return res
  }

  printMasuk(id) {
    return http({
      url: `/purchase/order/${id}/print_stock`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  printKeluar(id) {
    return http({
      url: `/sales/order/${id}/print_stock`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
